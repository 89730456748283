import React from 'react'
import { translate } from "react-i18next"

import Layout from '../components/layouts/baseLayout'
import Navbar from '../components/navbar/navbar'
import MissingTranslation from '../components/missing_translation/missing_translation'

import allStyle from "../styles/all.module.scss"


function Terms (props) {
  const { t } = props;
  return (
    <Layout>

      <main className={allStyle.annexe}>
        <div className={allStyle.page}>
          <MissingTranslation/>

          <div className={allStyle.title_links}>
            <h3>Politique de confidentialité</h3>
            <a href="#responsable">1. Responsable du traitement</a>
            <a href="#finalite">2. Finalités du traitement et base juridique</a>
            <a href="#destinataires">3. Destinataires des données à caractère personnel </a>
            <a href="#donnees">4. Données traitées </a>
            <a href="#duree">5. Durée de conservation des données à caractère personnel</a>
            <a href="#acces">6. Droit d’accès et de rectification</a>
            <a href="#effacement">7. Droit d'effacement et de retrait du consentement</a>
            <a href="#limitation">8. Droit de limitation</a>
            <a href="#portabilite">9. Droit de portabilité</a>
            <a href="#opposition">10. Droit d’opposition </a>
          </div>

          <p className={allStyle.intro}>
              Les données à caractère personnel communiquées par vous à l’Université de Liège dans le cadre de votre participation à la plateforme UGo sont collectées et traitées par l’Université dans le respect du Règlement Général sur le Protection des Données de l’Union européenne (UE 2016/679) et de la loi du 30 juillet 2018 relative à la protection des personnes physiques à l'égard des traitements de données à caractère personnel.
          <br/>
          <br/>
              Les données ainsi communiquées seront traitées par l’Université uniquement aux fins décrites ci-après. L’Université tient à vous assurer de la mise en œuvre de mesures de sécurité destinées à protéger les données à caractère personnel que vous aurez communiquées.
          </p>
          <div id="responsable">
            <h3 className={allStyle.section_title}>1. Responsable du traitement</h3>
            <p>Afin de se conformer aux obligations légales, l’Université identifie ici précisément le responsable du traitement des données à caractère personnel : l’Université de Liège, Place du 20 Août, 7 à 4000 Liège, identifiée à la Banque Carrefour des entreprises sous le numéro 0325.777.171 dûment représentée par Monsieur le Recteur Pierre Wolper.
            </p>
          </div>
          <div id="finalite">
            <h3 className={allStyle.section_title}>2. Finalités du traitement et base juridique</h3>
            <p>L’Université collecte et traite les données à caractère personnel afin de créer une banque de données
              appelée « Plateforme de covoiturage dynamique UGo » laquelle permet aux utilisateurs, membres de la
              communauté universitaire, ses Alumni et membres issus des communautés partenaires de l’Université, qui se sont
              enregistrés à l’aide du formulaire d’inscription en ligne, d’être mis en relation avec pour finalité de trouver une solution de covoiturage.
            <br/>
            <br/>
                De plus, l’Université traite également ces données à caractère personnel à des fins statistiques.
            <br/>
            <br/>
                Ces données seront traitées sur la base de la relation contractuelle (Art. 6-1, b. du RGPD).
            </p>
          </div>
          <div id="destinataires">
            <h3 className={allStyle.section_title}>3. Destinataires des données à caractère personnel</h3>
            <p>Les données à caractère personnel collectées par l’Université sont utilisées à des fins de fonctionnement de la plateforme.
                <br/>
                <br/>
                Ces données ne seront en aucun cas utilisées à des fins commerciales.
                <br/>
                <br/>
                Elles ne sont communiquées à des tiers, à savoir les utilisateurs de la plateforme, que si vous l’y autorisez expressément via le formulaire d’enregistrement à la plateforme.
                <br/>
                <br/>
                Les données à caractère personnel sont également utilisées en interne mais uniquement par le service chargé du développement de la plateforme, dans la proportion qui est respectivement nécessaire au bon fonctionnement de la plateforme.
            </p>
          </div>
          <div id="donnees">
            <h3 className={allStyle.section_title}>4. Données traitées</h3>
            <p>Les données traitées sont celles que vous communiquez lors de votre inscription à la plateforme
              (nom, prénom, adresse, éventuellement plaque d’immatriculation et modèle du véhicule) ou que vous communiquerez à l’Université
              ultérieurement sur base volontaire. </p>
            <p>Par ailleurs, l’Université utilise des cookies, fichiers d’information enregistrés automatiquement
                quand vous visitez le site et qui permettent de conserver durant une période limitée (12 mois) des
                informations sur votre visite et ce à des fins statistiques et d’amélioration de la plateforme.
                Ceux-ci peuvent être désactivés par les réglages utiles de votre système d’exploitation.
                L’Université tient à attirer votre attention sur le fait que, dans le cadre de cette activité
                de collecte de statistiques, d’autres cookies (liés à l’utilisation de Google Analytics) dont
                l’Université n’est pas responsable sont susceptibles d’être activés.
                Ils peuvent être désactivés par les mêmes procédés.
            </p>
          </div>
            <div id="duree">
                <h3 className={allStyle.section_title}>5. Durée de conservation des données à caractère personnel</h3>
                <p>
                    Les données à caractère personnel recueillies dans le cadre du présent service sont conservées le temps de l’affiliation à la plateforme UGo. Il est possible que certaines données soient conservées après que l'affiliation soit terminée, auquel cas les données sont anonymisées.
                </p>
                <p>
                    Au bout d'un délais d'inactivité de deux ans, et après avertissement, les données à caractère personnel sont automatiquement supprimées de la plateforme ou anonymisées.
                </p>
            </div>
          <div id="acces">
            <h3 className={allStyle.section_title}>6. Droit d’accès et de rectification</h3>
            <p>Les personnes ayant communiqué des données à caractère personnel à l’Université dans ce cadre bénéficient
              d’un droit personnel d’accès aux données les concernant et traitées par l’Université. </p>
            <p>À cette fin, la personne concernée doit adresser à l’Université une demande écrite,
                datée et signée à laquelle est jointe la preuve de son identité (copie de sa carte d’identité).
                Cette demande peut être envoyée par la poste ou par un moyen de communication électronique
                (e-mail :<a href="mailto:ugo@uliege.be">ugo@uliege.be</a> un e-mail ne sera recevable que s’il porte une
                signature électronique)
                ou être délivrée sur place (Adresse : Université de Liège - SEGI
              Allée de la Découverte 8, Quartier Polytech 1
              4000 Liège, Belgique) en mains propres. </p>
            <p>La personne concernée peut demander à l’Université de rectifier les données la concernant par les mêmes
                voies de communication que celles prévues à l’alinéa précédent.
            </p>
          </div>
            <div id="effacement">
                <h3 className={allStyle.section_title}>7. Droit d’effacement et de retrait du consentement</h3>
                <p>Les personnes ayant communiqué des données à caractère personnel à l'Université dans ce cadre bénéficient d'un droit d’effacement des données les concernant et traitées par l'Université.
                    <br/>
                    <br/>
                    A cette fin, la personne concernée doit adresser à l'Université une demande écrite,
                    datée et signée, à laquelle est jointe la preuve de son identité (copie de sa carte d'identité).
                    Cette demande peut être envoyée par la poste ou par un moyen de communication électronique
                    (e-mail : <a href="mailto:ugo@uliege.be">ugo@uliege.be</a> un e-mail ne sera recevable que s'il porte une
                    signature électronique)
                    ou être délivrée sur place (Adresse : Université de Liège - SEGI
                    Allée de la Découverte 8, Quartier Polytech 1
                    4000 Liège, Belgique) en mains propres. Vous pouvez également adresser cette demande au Délégué à la protection des données de l’Université de Liège (<a href="mailto:dpo@uliege.be">dpo@uliege.be</a> – Monsieur le Délégué à la Protection des Données, Bât. B9 Cellule « GDPR », Quartier Village 3, Boulevard de Colonster 2, 4000 Liège, Belgique).
                    <br/>
                    <br/>
                    La personne concernée peut retirer son consentement au traitement de ses données à caractère personnel par l’Université par les mêmes voies de communication que celles prévues à l'alinéa précédent.
                    <br/>
                    <br/>
                    L’effacement des données à caractère personnel tout comme le retrait du consentement mettent un terme au service offert par la plateforme CovoitULiège.

                </p>
            </div>
            <div id="limitation">
                <h3 className={allStyle.section_title}>8. Droit de limitation</h3>
                <p>Les personnes ayant communiqué des données à caractère personnel à l'Université dans ce cadre bénéficient d'un droit de limitation du traitement des données les concernant, sous réserve du respect de la réglementation en vigueur.
                    <br/>
                    <br/>
                    A cette fin, la personne concernée doit adresser à l'Université une demande écrite,
                    datée et signée, à laquelle est jointe la preuve de son identité (copie de sa carte d'identité).
                    Cette demande peut être envoyée par la poste ou par un moyen de communication électronique
                    (e-mail : <a href="mailto:ugo@uliege.be">ugo@uliege.be</a> un e-mail ne sera recevable que s'il porte une
                    signature électronique)
                    ou être délivrée sur place (Adresse : Université de Liège - SEGI
                    Allée de la Découverte 8, Quartier Polytech 1
                    4000 Liège, Belgique) en mains propres. Vous pouvez également adresser cette demande au Délégué à la protection des données de l’Université de Liège (<a href="mailto:dpo@uliege.be">dpo@uliege.be</a> – Monsieur le Délégué à la Protection des Données, Bât. B9 Cellule « GDPR », Quartier Village 3, Boulevard de Colonster 2, 4000 Liège, Belgique).
                </p>
            </div>
            <div id="portabilite">
                <h3 className={allStyle.section_title}>9. Droit de portabilité</h3>
                <p>Les personnes ayant communiqué des données à caractère personnel à l'Université dans ce cadre bénéficient d'un droit de portabilité des données les concernant et traitées par l'Université.
                    <br/>
                    <br/>
                    A cette fin, la personne concernée doit adresser à l'Université une demande écrite,
                    datée et signée, à laquelle est jointe la preuve de son identité (copie de sa carte d'identité).
                    Cette demande peut être envoyée par la poste ou par un moyen de communication électronique
                    (e-mail : <a href="mailto:ugo@uliege.be">ugo@uliege.be</a> un e-mail ne sera recevable que s'il porte une
                    signature électronique)
                    ou être délivrée sur place (Adresse : Université de Liège - SEGI
                    Allée de la Découverte 8, Quartier Polytech 1
                    4000 Liège, Belgique) en mains propres. Vous pouvez également adresser cette demande au Délégué à la protection des données de l’Université de Liège (<a href="mailto:dpo@uliege.be">dpo@uliege.be</a> – Monsieur le Délégué à la Protection des Données, Bât. B9 Cellule « GDPR », Quartier Village 3, Boulevard de Colonster 2, 4000 Liège, Belgique).

                </p>
            </div>
          <div id="opposition">
            <h3 className={allStyle.section_title}>10. Droit d’opposition</h3>
            <p>La personne concernée peut s’opposer au traitement de ses données à caractère personnel, en démontrant en
              quoi le traitement entrepris par l’Université peut lui porter préjudice.
              A cette fin, la personne concernée doit adresser à l'Université une demande écrite,
              datée et signée, à laquelle est jointe la preuve de son identité (copie de sa carte d'identité).
                Cette demande peut être envoyée par la poste ou par un moyen de communication électronique
                (e-mail : <a href="mailto:ugo@uliege.be">ugo@uliege.be</a> un e-mail ne sera recevable que s'il porte une
                signature électronique)
                ou être délivrée sur place (Adresse : Université de Liège - SEGI
              Allée de la Découverte 8, Quartier Polytech 1
              4000 Liège, Belgique) en mains propres. Vous pouvez également adresser cette demande au Délégué à la protection des données de l’Université de Liège (<a href="mailto:dpo@uliege.be">dpo@uliege.be</a> – Monsieur le Délégué à la Protection des Données, Bât. B9 Cellule « GDPR », Quartier Village 3, Boulevard de Colonster 2, 4000 Liège, Belgique).

                <br/>
                <br/>

                Vous disposez également du droit d’introduire une réclamation auprès de l’Autorité de protection des données (https://www.autoriteprotectiondonnees.be, <a href="mailto:contact@apd-gba.be">contact@apd-gba.be</a>).
            </p>
          </div>



        </div>
      </main>
    </Layout>)
}


export default translate("terms")(Terms)