import React, {Component} from "react"
import * as i18n from "i18next";
import {translate} from "react-i18next"

import style from './missing_translation.module.scss'


class MissingTranslation extends Component {
    constructor(props) {
        super(props)
        const { i18n } = this.props
        this.state = { language: i18n.language }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ language: nextProps.i18n.language })
    }

    render() {
        const { t } = this.props;

        if (this.state.language !== "fr-BE") {
            return (
                <div className={style.container}>
                    <p className={style.text}>{t("missing_translation")}</p>
                </div>
            )
        } else {
            return null;
        }
    }
}

export default translate("all")(MissingTranslation);